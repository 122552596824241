import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ employee off-boarding policy.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Elements</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Disciplinary Consequences</AnchorLink>
    </AnchorLinks>
    <h2>{`Purpose`}</h2>
    <p>{`Our employees systems and data `}</p>
    <p>{`It is in Data Migrators’ interest to effectively manage the departure of employees when their employment ends. This off-boarding process can help to ensure the:`}</p>
    <ul>
      <li parentName="ul">{`Security of property and information`}</li>
      <li parentName="ul">{`Completion of required exit tasks`}</li>
      <li parentName="ul">{`Business continuity upon the employee’s departure`}</li>
      <li parentName="ul">{`Transition with staff and clients`}</li>
      <li parentName="ul">{`Collection of information and insights about the Data Migrators employment experience`}</li>
    </ul>
    <h2>{`Scope`}</h2>
    <p>{`This policy affects all employees, including board members, investors, contractors and volunteers, who may have access to confidential information.`}</p>
    <h2>{`Policy Steps`}</h2>
    <p>{`When a letter of resignation is received and/or Data Migrators initiates a final separation action (Termination/Dismissal, Medical Separation, Layoff, Job Abandonment, etc.) then Data Migrators informs the employee that all Company property and electronic resources, access, and documents must be returned/secured upon separation.  We will discuss a transition plan and the transfer of information.  Data Migrators completes the Supervisor Checklist for Off-boarding Employees.`}</p>
    <h2>{`Supervisor Checklist for Off-boarding Employees`}</h2>
    <h3>{`Summary`}</h3>
    <p>{`A suitably-qualified administrator appointed by a Data Migrators Director will take take the following actions:`}</p>
    <ul>
      <li parentName="ul">{`Check employee rights in their jurisdiction and implications for off-boarding.`}</li>
      <li parentName="ul">{`Notify the employee of termination, including`}
        <ul parentName="li">
          <li parentName="ul">{`any factors that have triggered it`}</li>
          <li parentName="ul">{`the process they’ve been through (e.g. performance management) leading up to termination`}</li>
          <li parentName="ul">{`the process they will go through as part of termination (e.g. post-employment placement support)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Removal of infrastructure access`}</li>
      <li parentName="ul">{`Notify office accommodation suppliers to remove access.`}</li>
      <li parentName="ul">{`Return of equipment`}</li>
      <li parentName="ul">{`Remote wipe where possible`}</li>
      <li parentName="ul">{`Revoke SaaS tool access (See below)`}</li>
      <li parentName="ul">{`Address IBM-related access`}
        <ul parentName="li">
          <li parentName="ul">{`Remove IBM PartnerWorld entry and access`}</li>
          <li parentName="ul">{`Notify IBM to remove user’s W3 access (IBM account, IBM Slack, etc.)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Access to Data Migrators premises is rescinded`}</li>
    </ul>
    <h3>{`Infrastructure and SaaS Services`}</h3>
    <p>{`Remove the user’s access to the following platforms and services:`}</p>
    <ul>
      <li parentName="ul">{`Suspend 1Password account`}</li>
      <li parentName="ul">{`Remove AWS access from All AWS accounts (assign owned objects to trusted administrator)`}
        <ul parentName="li">
          <li parentName="ul">{`Remove all API keys (which will suspend access via Transmit, Dash, etc.)`}</li>
          <li parentName="ul">{`Remote all used-related Security Group entries`}</li>
          <li parentName="ul">{`Remove all IAM accounts`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Suspend access to Google Workspace account`}
        <ul parentName="li">
          <li parentName="ul">{`Delete pending review`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Suspend Office365 account`}
        <ul parentName="li">
          <li parentName="ul">{`Verify AzureDevOps is suspended`}</li>
          <li parentName="ul">{`Remove user’s Azure API keys`}</li>
          <li parentName="ul">{`Delete pending review`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Archive Atlassian account`}
        <ul parentName="li">
          <li parentName="ul">{`This covers Jira, Confluence and Bitbucket`}</li>
          <li parentName="ul">{`Delete account pending review`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Delete accounts from SaaS ALM systems`}
        <ul parentName="li">
          <li parentName="ul">{`GitHub`}</li>
          <li parentName="ul">{`Azure DevOps`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Delete accounts from self-hosted ALM systems (`}<inlineCode parentName="li">{`dm-demo-alm.*`}</inlineCode>{`)`}
        <ul parentName="li">
          <li parentName="ul">{`Azure Server (self-hosted)`}</li>
          <li parentName="ul">{`GitHub Server (self-hosted)`}</li>
          <li parentName="ul">{`GitLab (self-hosted)`}</li>
          <li parentName="ul">{`Jenkins (self-hosted)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Verify then remove user’s objects on the AWS dm-sandbox account`}</li>
      <li parentName="ul">{`Remove any user-specific accounts on `}<inlineCode parentName="li">{`dm-demo-alm`}</inlineCode>{` and `}<inlineCode parentName="li">{`dm-demo-datastage`}</inlineCode>{` environments`}</li>
      <li parentName="ul">{`Offboard in Xero`}</li>
      <li parentName="ul">{`Offboard in Harvest
Remove any relevant account from the following services: `}</li>
      <li parentName="ul">{`Bitdefender`}</li>
      <li parentName="ul">{`Box`}</li>
      <li parentName="ul">{`Slack`}</li>
      <li parentName="ul">{`Zoom`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      